<template>
  <div class="main">
    <div class="box-top"></div>
    <div class="box-m"></div>
    <div class="box sc-width d-flex">
      <div class="left-menu">
        <user-left-menu/>
      </div>


      <div class=" main-box">
        <div class="cards">
          <div>
            <div
              class="flex search-top-menu-top"
              style="justify-content: space-between"
            >
              <div class="search-top-menu">
                <div
                  class="item"
                  v-for="(val, index) in checkValueArr"
                  :key="index"
                >
                  <div
                    :class="[
                      'value',
                      'search-button',
                      { 'value-active': val.checkValue == checkValue }
                    ]"
                    @click="changeCheckValue(val.checkValue)"
                  >
                    {{ val.label }}( {{ val.value }} )
                  </div>
                  <div class="line" v-if="val.checkValue == checkValue"></div>
                </div>
              </div>
              <div class="type">
                <div class="value div-flex">
                  <div
                    class="value-item search-button div-flex"
                    @click="changeSearchType('4')"
                  >
                    <div class="item-icon ">
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('4') === -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_11_80)">
                            <rect
                              x="4"
                              y="4"
                              width="16"
                              height="16"
                              rx="4"
                              fill="#F9F9F9"
                            />
                            <rect
                              x="5"
                              y="5"
                              width="14"
                              height="14"
                              rx="3"
                              stroke="#000"
                              stroke-width="2"
                            />
                            <path
                              d="M-3 11.9375L-0.565217 15L5 8"
                              stroke="#000"
                              stroke-width="2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_11_80">
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="4"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('4') > -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="#F9F9F9"
                          />
                          <rect
                            x="5"
                            y="5"
                            width="14"
                            height="14"
                            rx="3"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <path
                            d="M8 11.9375L10.4348 15L16 8"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            stroke="#F57F45"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="item-word">Clearance ({{ typeCate[4] }})</div>
                  </div>
                  <div
                    class="value-item search-button div-flex"
                    @click="changeSearchType('2')"
                  >
                    <div class="item-icon">
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('2') === -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_11_80)">
                            <rect
                              x="4"
                              y="4"
                              width="16"
                              height="16"
                              rx="4"
                              fill="#F9F9F9"
                            />
                            <rect
                              x="5"
                              y="5"
                              width="14"
                              height="14"
                              rx="3"
                              stroke="#000"
                              stroke-width="2"
                            />
                            <path
                              d="M-3 11.9375L-0.565217 15L5 8"
                              stroke="#000"
                              stroke-width="2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_11_80">
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="4"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('2') > -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="#F9F9F9"
                          />
                          <rect
                            x="5"
                            y="5"
                            width="14"
                            height="14"
                            rx="3"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <path
                            d="M8 11.9375L10.4348 15L16 8"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            stroke="#F57F45"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="item-word">On Sale ({{ typeCate[2] }})</div>
                  </div>
                  <div
                    class="value-item search-button div-flex"
                    @click="changeSearchType('1')"
                  >
                    <div class="item-icon">
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('1') === -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_11_80)">
                            <rect
                              x="4"
                              y="4"
                              width="16"
                              height="16"
                              rx="4"
                              fill="#F9F9F9"
                            />
                            <rect
                              x="5"
                              y="5"
                              width="14"
                              height="14"
                              rx="3"
                              stroke="#000"
                              stroke-width="2"
                            />
                            <path
                              d="M-3 11.9375L-0.565217 15L5 8"
                              stroke="#000"
                              stroke-width="2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_11_80">
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="4"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('1') > -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="#F9F9F9"
                          />
                          <rect
                            x="5"
                            y="5"
                            width="14"
                            height="14"
                            rx="3"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <path
                            d="M8 11.9375L10.4348 15L16 8"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            stroke="#F57F45"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="item-word">Sourcing ({{ typeCate[1] }})</div>
                  </div>
                  <div
                    class="value-item search-button div-flex"
                    @click="changeSearchType('3')"
                  >
                    <div class="item-icon">
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('3') === -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_11_80)">
                            <rect
                              x="4"
                              y="4"
                              width="16"
                              height="16"
                              rx="4"
                              fill="#F9F9F9"
                            />
                            <rect
                              x="5"
                              y="5"
                              width="14"
                              height="14"
                              rx="3"
                              stroke="#000"
                              stroke-width="2"
                            />
                            <path
                              d="M-3 11.9375L-0.565217 15L5 8"
                              stroke="#000"
                              stroke-width="2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_11_80">
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="4"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        class="icon"
                        v-if="filter.search_type.indexOf('3') > -1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="#F9F9F9"
                          />
                          <rect
                            x="5"
                            y="5"
                            width="14"
                            height="14"
                            rx="3"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <path
                            d="M8 11.9375L10.4348 15L16 8"
                            stroke="#F57F45"
                            stroke-width="2"
                          />
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            stroke="#F57F45"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="item-word">New Product ({{ typeCate[3] }})</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="border"></div>
            <div class="search">
              <div class="left">
                <div class="search-icon">
                  <img src="@/assets/sourcing/search-icon.svg"/>
                </div>
                <div class="search-input">
                  <input
                    type="text"
                    placeholder="Search..."
                    @keydown.enter="getSourcings"
                    v-model="filter.product_name"
                  />
                </div>
              </div>

              <div class="right" style="display: flex">
                <router-link to="/buy">
                  <div class="add-sourcing search-button">
                    Buy Product
                  </div>
                </router-link>
                <router-link to="/sell">
                  <div class="add-quotation search-button">
                    Sell Product
                  </div>
                </router-link>

                <div
                  class="my-complete-list search-button complete-list"
                  @click="myCompleteList"
                >
                  My Complete List
                </div>
              </div>
            </div>
            <div class="list-table">
              <div class="title">
                <!--                <div class="item product font-medium">Company</div>-->
                <div class="item attendee font-medium">Product</div>
                <div class="item size font-medium">Quoted Quantity</div>
                <div class="item size font-medium status-col">Type</div>
                <div class="item size font-medium status-col-2">Status</div>
                <div class="item date font-medium">Date</div>
                <div class="item date font-medium">Action</div>
              </div>
              <div class="content">
                <div class="loading-pic" v-if="loading">
                  <img src="@/assets/loading.gif" />
                </div>
                <div
                  v-for="(item, index) in list"
                  :key="index"
                  :class="['item', { active: activeIndex === item.id }]"
                  @mouseover="companyMouseOver(item.id)"
                  @mouseleave="companyMouseLeave()"
                >
                  <div class="top search-button">
                    <div class="attendee font-medium" @click="view(item)">
                      {{ item.product_name || "--" }}
                    </div>
                    <div
                      :class="[
                        'size',
                        'font-medium',
                        { 'have-count': item.quotes_count > 0 }
                      ]"
                      @click="view(item)"
                    >
                      {{ item.quotes_count }}
                    </div>
                    <div class="status-col font-medium" @click="view(item)">
                      {{ item.type_desc || "--" }}
                    </div>
                    <div class="status-col-2 font-medium" @click="view(item)">
                      <div
                        class="status status-progress"
                        v-if="item.status == 1"
                      >
                        In progress
                      </div>
                      <div
                        class="status status-completed"
                        v-if="item.status == 2"
                      >
                        Completed
                      </div>
                      <div
                        class="status status-expired"
                        v-if="item.status == 3"
                      >
                        Expired
                      </div>
                    </div>
                    <div class="date font-medium" @click="view(item)">
                      {{
                        (item.last_quote_date
                          ? item.last_quote_date
                          : item.created_at) | moment
                      }}
                    </div>
                    <div class="date font-medium" @click="showMenu(item.id)">
                      <svg
                        width="6"
                        height="20"
                        viewBox="0 0 6 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.99247 7.80488C4.18294 7.80488 5.13532 8.78049 5.13532 10C5.13532 11.2195 4.18294 12.1951 2.99247 12.1951C1.80199 12.1951 0.849609 11.2195 0.849609 10C0.849609 8.78049 1.80199 7.80488 2.99247 7.80488ZM0.849609 2.19512C0.849609 3.41463 1.80199 4.39024 2.99247 4.39024C4.18294 4.39024 5.13532 3.41463 5.13532 2.19512C5.13532 0.97561 4.18294 0 2.99247 0C1.80199 0 0.849609 0.97561 0.849609 2.19512ZM0.849609 17.8049C0.849609 19.0244 1.80199 20 2.99247 20C4.18294 20 5.13532 19.0244 5.13532 17.8049C5.13532 16.5854 4.18294 15.6098 2.99247 15.6098C1.80199 15.6098 0.849609 16.5854 0.849609 17.8049Z"
                          fill="#8B83BA"
                        />
                      </svg>
                      <div
                        class="detail-menu"
                        v-if="isShowMenu == item.id && item.status == 1"
                      >
                        <div
                          class="menu-item search-button"
                          @click="editItem(item)"
                        >
                          Edit
                        </div>
                        <div
                          class="menu-item search-button"
                          @click="copyItem(item)"
                        >
                          Duplicate
                        </div>
                        <div
                          class="menu-item search-button"
                          @click="closeItem(item.id)"
                        >
                          Complete
                        </div>
                        <div
                          class="menu-item menu-delete search-button"
                          @click="showClose(item)"
                        >
                          Delete
                        </div>
                      </div>
                      <div
                        class="detail-menu detail-menu-close"
                        v-if="isShowMenu == item.id && item.status !== 1"
                      >
                        <div
                          class="menu-item search-button"
                          @click="viewItem(item)"
                        >
                          View
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty" v-if="list.length === 0 && !loading">
              No Data
            </div>
            <div class="page">
              <Page
                transfer
                :page-size="limit"
                :total="total"
                show-elevator
                show-total
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-m"></div>
    <EditInquiry
      :isEditInquiry="isEditInquiry"
      ref="editInquiry"
      @getSourcings="getSourcings"
    ></EditInquiry>
    <EditQuotation
      :isEditInquiry="isEditInquiry"
      ref="editQuotation"
      @getSourcings="getSourcings"
    ></EditQuotation>
    <CopyQuotation
      :isEditInquiry="isEditInquiry"
      ref="copyQuotation"
      @getSourcings="getSourcings"
    ></CopyQuotation>
    <CopyInquiry
      :isEditInquiry="isEditInquiry"
      ref="copyInquiry"
      @getSourcings="getSourcings"
    ></CopyInquiry>

    <Modal
      v-model="isShowClose"
      class="show-close-modal"
      footer-hide
      style="width: 410px !important;"
    >
      <div class="show-close">
        <div class="content">
          <span class="warning-logo">
            <svg
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 25C17.6522 25 20.1957 23.9464 22.0711 22.0711C23.9464 20.1957 25 17.6522 25 15C25 12.3478 23.9464 9.8043 22.0711 7.92893C20.1957 6.05357 17.6522 5 15 5C12.3478 5 9.8043 6.05357 7.92893 7.92893C6.05357 9.8043 5 12.3478 5 15C5 17.6522 6.05357 20.1957 7.92893 22.0711C9.8043 23.9464 12.3478 25 15 25V25ZM15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C21.9037 2.5 27.5 8.09625 27.5 15C27.5 21.9037 21.9037 27.5 15 27.5ZM13.75 20H16.25V22.5H13.75V20ZM13.75 7.5H16.25V17.5H13.75V7.5Z"
                fill="#FF0000"
              />
            </svg>
          </span>
          Are you sure you want to delete this item?
        </div>
        <div class="btn">
          <div
            class="confirm search-button"
            @click="deleteItem(closeInquiry.id)"
            v-if="!closeLoading"
          >
            Confirm
          </div>
          <div class="confirm loading-button" v-else>
            <img src="@/assets/loading.gif" />
          </div>
        </div>
      </div>
    </Modal>
    <editQuotationImages ref="editQuotationImages"></editQuotationImages>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
import moment from "moment";
import EditInquiry from "@/components/editInquiry";
import EditQuotation from "@/components/editQuotation";
import CopyQuotation from "@/components/copyQuotation";
import CopyInquiry from "@/components/copyInquiry";
import editQuotationImages from "@/components/editQuotationImages";
import UserLeftMenu from "@/components/userLeftMenu";

const {
  _queryMySourcing,
  _queryMySourcingType,
  _closeMySourcing,
  _deleteMySourcing,
  _queryMySourcingTypeCate
} = api;

export default {
  name: "products",
  computed: {
    ...mapState("user", ["role", "company_id"])
  },
  components: {
    EditInquiry,
    EditQuotation,
    CopyInquiry,
    CopyQuotation,
    editQuotationImages,UserLeftMenu
  },
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      cateList: [],
      countryList: [],
      filter: {
        product_name: "",
        // user_id: 0,
        // involved: 0,
        search_type: ["1", "2", "3", "4"]
        // country: 0,
        // company: 0,
        // new: 1
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false,
      activeIndex: 0,
      showInstruction: false,
      allNum: 0,
      progressNum: 0,
      completedNum: 0,
      expiredNum: 0,
      checkValue: 0,
      checkValueArr: [
        { label: "All", checkValue: 0, value: 0 },
        { label: "In progress", checkValue: 1, value: 0 },
        { label: "Completed", checkValue: 2, value: 0 },
        { label: "Expired", checkValue: 3, value: 0 }
      ],
      inquiry: {},
      isShowMenu: 0,
      isShowClose: false,
      closeInquiry: {},
      closeLoading: false,
      isEditInquiry: false,
      typeCate: { 1: 0, 2: 0, 3: 0, 4: 0 }
    };
  },
  mounted() {
    this.getSourcings();
    this.getMySourcingType();
    this.getSourcingTypeCate();
  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    }
  },
  methods: {
    myCompleteList() {
      this.$refs.editQuotationImages.showUpload = true;
      this.$refs.editQuotationImages.companyId = this.company_id;
    },
    getSourcingTypeCate() {
      _queryMySourcingTypeCate({ ...this.filter }).then(response => {
        const { data } = response;
        this.typeCate = data;
      });
    },
    changeSearchType(val) {
      var index = this.filter.search_type.indexOf(val);
      if (index > -1) {
        this.filter.search_type.splice(index, 1);
      } else {
        this.filter.search_type.push(val);
      }
      this.getSourcings();
      this.getMySourcingType();
      this.getSourcingTypeCate();
    },
    showClose(item) {
      this.isShowClose = true;
      this.closeInquiry = item;
    },
    editItem(item) {
      if (item.type === 1) {
        this.$refs.editInquiry.showInquiry = true;
        this.$refs.editInquiry.editInquiryForm.type = 1;
        this.$refs.editInquiry.editInquiryForm.delivery_address =
          item.delivery_address;
        this.$refs.editInquiry.editInquiryForm.application = item.application;
        this.$refs.editInquiry.editInquiryForm.estimated_annual_quantity =
          item.estimated_annual_quantity;
        this.$refs.editInquiry.editInquiryForm.sourcing_type =
          item.sourcing_type;
        this.$refs.editInquiry.editInquiryForm.note = item.note;
        this.$refs.editInquiry.editInquiryForm.sample_request =
          item.sample_request;
        this.$refs.editInquiry.editInquiryForm.hidden_company =
          item.hidden_company;
        this.$refs.editInquiry.editInquiryForm.delivery_date = item.delivery_date
          ? item.delivery_date.slice(0, 10)
          : null;
        this.$refs.editInquiry.editInquiryForm.id = item.id;
        this.$refs.editInquiry.editInquiryForm.product_name = item.product_name;
        this.$refs.editInquiry.editInquiryForm.quality_standard =
          item.quality_standard;
        this.$refs.editInquiry.editInquiryForm.quantity = item.quantity;
        this.$refs.editInquiry.editInquiryForm.packaging = item.packaging;
        this.$refs.editInquiry.editInquiryForm.in_stock = item.in_stock;
        this.$refs.editInquiry.editInquiryForm.required_documents =
          item.required_documents;
        this.$refs.editInquiry.editInquiryForm.expire_date = item.expire_date
          ? item.expire_date.slice(0, 10)
          : null;
        this.$refs.editInquiry.editInquiryForm.basis = item.basis;
        this.$refs.editInquiry.editInquiryForm.country = item.country;
        this.$refs.editInquiry.editInquiryForm.other_names = item.other_names;
        this.$refs.editInquiry.isEdit = true;
        this.$refs.editInquiry.editInquiryForm.vendor_company =
          item.vendor_company;
        this.$refs.editInquiry.editInquiryForm.vendor_address =
          item.vendor_address;
        this.$refs.editInquiry.editInquiryForm.vendor_contact =
          item.vendor_contact;
        this.$refs.editInquiry.editInquiryForm.vendor_email = item.vendor_email;
        this.$refs.editInquiry.editInquiryForm.vendor_tel = item.vendor_tel;
        this.$refs.editInquiry.editInquiryForm.vendor_note = item.vendor_note;
        this.$refs.editInquiry.coaFile =
          item.coa.length > 0 ? item.coa[0]["url"] : null;
      } else {
        this.$refs.editQuotation.showInquiry = true;
        this.$refs.editQuotation.editInquiryForm.id = item.id;
        this.$refs.editQuotation.editInquiryForm.product_name =
          item.product_name;
        this.$refs.editQuotation.editInquiryForm.quality_standard =
          item.quality_standard;
        this.$refs.editQuotation.editInquiryForm.quantity = item.quantity;
        this.$refs.editQuotation.editInquiryForm.packaging = item.packaging;
        this.$refs.editQuotation.editInquiryForm.in_stock = item.in_stock;
        this.$refs.editQuotation.editInquiryForm.required_documents =
          item.required_documents;
        this.$refs.editQuotation.editInquiryForm.expire_date = item.expire_date
          ? item.expire_date.slice(0, 10)
          : null;
        this.$refs.editQuotation.editInquiryForm.basis = item.basis;
        this.$refs.editQuotation.isEdit = true;
        this.$refs.editQuotation.editInquiryForm.factory = item.factory;
        this.$refs.editQuotation.editInquiryForm.product_expire_date = item.product_expire_date
          ? item.product_expire_date.slice(0, 10)
          : null;
        this.$refs.editQuotation.editInquiryForm.hidden_company =
          item.hidden_company;
        this.$refs.editQuotation.editInquiryForm.lead_time = item.lead_time;
        this.$refs.editQuotation.editInquiryForm.code = item.code;
        this.$refs.editQuotation.editInquiryForm.shipping_address =
          item.shipping_address;
        this.$refs.editQuotation.editInquiryForm.price = item.price;
        this.$refs.editQuotation.editInquiryForm.type = item.type;
        this.$refs.editQuotation.editInquiryForm.country = item.country;
        this.$refs.editQuotation.editInquiryForm.other_names = item.other_names;
        this.$refs.editQuotation.editInquiryForm.note = item.note;
        this.$refs.editQuotation.qcFile =
          item.qc.length > 0 ? item.qc[0]["url"] : null;
        this.$refs.editQuotation.coaFile =
          item.coa.length > 0 ? item.coa[0]["url"] : null;
        this.$refs.editQuotation.editInquiryForm.vendor_company =
          item.vendor_company;
        this.$refs.editQuotation.editInquiryForm.vendor_address =
          item.vendor_address;
        this.$refs.editQuotation.editInquiryForm.vendor_contact =
          item.vendor_contact;
        this.$refs.editQuotation.editInquiryForm.vendor_email =
          item.vendor_email;
        this.$refs.editQuotation.editInquiryForm.vendor_tel = item.vendor_tel;
        this.$refs.editQuotation.editInquiryForm.vendor_note = item.vendor_note;
      }
    },
    copyItem(item) {
      if (item.type === 1) {
        this.$refs.copyInquiry.showInquiry = true;
        this.$refs.copyInquiry.editInquiryForm.type = 1;
        this.$refs.copyInquiry.editInquiryForm.delivery_address =
          item.delivery_address;
        this.$refs.copyInquiry.editInquiryForm.application = item.application;
        this.$refs.copyInquiry.editInquiryForm.estimated_annual_quantity =
          item.estimated_annual_quantity;
        this.$refs.copyInquiry.editInquiryForm.sourcing_type =
          item.sourcing_type;
        this.$refs.copyInquiry.editInquiryForm.note = item.note;
        this.$refs.copyInquiry.editInquiryForm.sample_request =
          item.sample_request;
        this.$refs.copyInquiry.editInquiryForm.hidden_company =
          item.hidden_company;
        this.$refs.copyInquiry.editInquiryForm.delivery_date = item.delivery_date
          ? item.delivery_date.slice(0, 10)
          : null;
        this.$refs.copyInquiry.editInquiryForm.id = item.id;
        this.$refs.copyInquiry.editInquiryForm.product_name = item.product_name;
        this.$refs.copyInquiry.editInquiryForm.quality_standard =
          item.quality_standard;
        this.$refs.copyInquiry.editInquiryForm.quantity = item.quantity;
        this.$refs.copyInquiry.editInquiryForm.packaging = item.packaging;
        this.$refs.copyInquiry.editInquiryForm.in_stock = item.in_stock;
        this.$refs.copyInquiry.editInquiryForm.required_documents =
          item.required_documents;
        this.$refs.copyInquiry.editInquiryForm.expire_date = item.expire_date
          ? item.expire_date.slice(0, 10)
          : null;
        this.$refs.copyInquiry.editInquiryForm.basis = item.basis;
        this.$refs.copyInquiry.editInquiryForm.country = item.country;
        this.$refs.copyInquiry.editInquiryForm.other_names = item.other_names;
        this.$refs.copyInquiry.isEdit = false;
      } else {
        this.$refs.copyQuotation.showInquiry = true;
        this.$refs.copyQuotation.editInquiryForm.id = item.id;
        this.$refs.copyQuotation.editInquiryForm.product_name =
          item.product_name;
        this.$refs.copyQuotation.editInquiryForm.quality_standard =
          item.quality_standard;
        this.$refs.copyQuotation.editInquiryForm.quantity = item.quantity;
        this.$refs.copyQuotation.editInquiryForm.packaging = item.packaging;
        this.$refs.copyQuotation.editInquiryForm.in_stock = item.in_stock;
        this.$refs.copyQuotation.editInquiryForm.required_documents =
          item.required_documents;
        this.$refs.copyQuotation.editInquiryForm.expire_date = item.expire_date
          ? item.expire_date.slice(0, 10)
          : null;
        this.$refs.copyQuotation.editInquiryForm.basis = item.basis;
        this.$refs.copyQuotation.isEdit = false;
        this.$refs.copyQuotation.editInquiryForm.factory = item.factory;
        this.$refs.copyQuotation.editInquiryForm.product_expire_date = item.product_expire_date
          ? item.product_expire_date.slice(0, 10)
          : null;
        this.$refs.copyQuotation.editInquiryForm.hidden_company =
          item.hidden_company;
        this.$refs.copyQuotation.editInquiryForm.lead_time = item.lead_time;
        this.$refs.copyQuotation.editInquiryForm.code = item.code;
        this.$refs.copyQuotation.editInquiryForm.shipping_address =
          item.shipping_address;
        this.$refs.copyQuotation.editInquiryForm.price = item.price;
        this.$refs.copyQuotation.editInquiryForm.type = item.type;
        this.$refs.copyQuotation.editInquiryForm.country = item.country;
        this.$refs.copyQuotation.editInquiryForm.other_names = item.other_names;
        this.$refs.copyQuotation.editInquiryForm.note = item.note;
        this.$refs.copyQuotation.qcFile =
          item.qc.length > 0 ? item.qc[0]["url"] : null;
        this.$refs.copyQuotation.coaFile =
          item.coa.length > 0 ? item.coa[0]["url"] : null;
      }
    },

    viewItem(item) {
      this.$refs.editInquiry.showInquiry = true;
      this.$refs.editInquiry.editInquiryForm.id = item.id;
      this.$refs.editInquiry.editInquiryForm.product_name = item.product_name;
      this.$refs.editInquiry.editInquiryForm.quality_standard =
        item.quality_standard;
      this.$refs.editInquiry.editInquiryForm.quantity = item.quantity;
      this.$refs.editInquiry.editInquiryForm.packaging = item.packaging;
      this.$refs.editInquiry.editInquiryForm.in_stock = item.in_stock;
      this.$refs.editInquiry.editInquiryForm.required_documents =
        item.required_documents;
      this.$refs.editInquiry.editInquiryForm.expire_date = item.expire_date.slice(
        0,
        10
      );
      this.$refs.editInquiry.editInquiryForm.basis_and_price =
        item.basis_and_price;
      this.$refs.editInquiry.isEdit = true;
      this.$refs.editInquiry.isView = true;
    },
    closeItem(id) {
      this.closeLoading = true;
      _closeMySourcing({
        id: id
      }).finally(() => {
        this.closeLoading = false;
        this.closeInquiry = {};
        this.isShowClose = false;
        this.getSourcings();
        this.getMySourcingType();
        this.getSourcingTypeCate();
      });
    },
    deleteItem(id) {
      this.closeLoading = true;
      _deleteMySourcing({
        id: id
      }).finally(() => {
        this.closeLoading = false;
        this.closeInquiry = {};
        this.isShowClose = false;
        this.getSourcings();
        this.getMySourcingType();
        this.getSourcingTypeCate();
      });
    },
    showMenu(id) {
      this.isShowMenu == id ? (this.isShowMenu = 0) : (this.isShowMenu = id);
    },
    view(item) {
      this.$router.push(`/my-inquiries-res/${item.id}`);
    },
    changeCheckValue(val) {
      this.checkValue = val;
      this.filter.status = val;
      this.getSourcings();
      this.getSourcingTypeCate();
    },
    login() {
      if (this.role === "") {
        this.$refs.popLogin.showLogin = true;
        return;
      } else {
        this.$router.push(`/sourcing-inquiry`);
      }
    },
    jumpTo(path) {
      if (this.role === "") {
        this.$router.push(
          `/user-action/login?redirect=${this.$route.fullPath}`
        );
        return;
      }
      this.$router.push(path);
    },
    showInstructionFun() {
      this.showInstruction = !this.showInstruction;
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSourcings();
    },

    handleSearch() {
      this.page = 1;
      this.getSourcings();
    },

    pageChange(page) {
      this.page = page;
      this.getSourcings();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSourcings();
    },
    getMySourcingType() {
      _queryMySourcingType({ ...this.filter }).then(response => {
        const { data } = response;
        this.checkValueArr = data;
      });
    },
    getSourcings() {
      this.loading = true;
      this.list = [];
      this.filter.search_type = this.filter.search_type.toString();
      _queryMySourcing({
        page: this.page,
        limit: this.limit,
        ...this.filter,
        type: 1
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
          if (this.filter.search_type === "0") {
            this.filter.search_type = ["2", "3", "4"];
          } else {
            this.filter.search_type = this.filter.search_type.split(",");
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    cancel() {
      this.loading = false;
      this.handleReset("form");
      this.industry_usage_grade_temp = "";
      this.compendial_standard_temp = "";
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
  .d-flex{
    display: flex;
  }
  .d-just-content-between{
    justify-content: space-between;
  }
@media (max-width: 1400px) {
  .search-top-menu-top {
    display: block !important;
    .type {
      margin-top: 25px !important;
      margin-left: 30px !important;
    }
  }
}
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 70px auto 0 auto;
  background: #f2f2f2;
  .main-box {
    background: #fff;
    border-radius: 15px;
  }
}
.box-m {
  height: 24px;
  background: #f2f2f2;
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  padding: 40px;
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 0;
  padding-right: 0;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }

  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;

    height: 38px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .add-quotation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;

    height: 38px;
    background: #fff;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #f57f45;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .my-complete-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;

    height: 38px;
    background: #ebbb45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;

    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  .left {
    display: flex;
    .search-input {
      width: 400px;
    }
  }
}
.main {
  background: #f2f2f2;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    min-height: 200px;
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
      }
      .size {
        width: 190px;
      }
      .status-col {
        width: 150px;
      }
      .status-col-2 {
        width: 180px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        height: 60px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 60px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 10px;
          }
          .status-col-2{
            padding-right: 20px;
          }
          .have-count {
            color: #f57f45;
            font-weight: 600;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .size {
            width: 190px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.search-top-menu {
  display: flex;
  padding: 40px 0 10px 40px;
  .item {
    margin-right: 25px;
    .value {
      font-size: 18px;
    }
    .value-active {
      color: #f57f45;
      font-weight: 600;
    }
    .line {
      height: 2px;
      background-color: #f57f45;
      position: relative;
      top: 11px;
    }
  }
}
.border {
  border-top: 1px solid #ececec;
}
.status {
  /*width: 90px;*/
  /*height: 30px;*/
  border-radius: 5px;
  display: flex;
  justify-content: center !important;
  color: #fff;
  font-size: 13px;
  align-items: center;
  padding: 5px 8px;
}
.status-progress {
  background: #11828f;
}
.status-expired {
  background: #c0399f;
}
.status-completed {
  background: #925acf;
}
.search {
  display: flex;
  padding-left: 7px;
  border: none;
  .search-icon {
    position: relative;
    left: 35px;
    top: 10px;
    img{
      width: 18px;
      height: 18px;
    }
  }
  .search-input {
    input {
      background: #f2f2f2;
      outline: none;
      border-radius: 10px;
      border: none;
      width: 280px;
      height: 40px;
      padding-left: 45px;
    }
    input:focus {
      border: 1px solid #f57f45;
      background: #fff;
    }
  }
}
.detail-menu-close {
  height: 30px !important;
}
.detail-menu {
  display: block !important;
  background: #fff;
  border-radius: 6px;
  width: 154px;
  height: 100px;
  position: relative;
  top: 40px;
  left: 15px;
  .menu-item {
    color: #25213b;
    font-size: 14px;
    padding: 10px 0 0 10px;
  }
  .menu-item:hover {
    background: #f2f0f9;
  }
  .menu-delete {
    color: #d30000;
  }
}
.loading-pic {
  margin-top: 40px;
  img {
    width: 60px;
    height: 60px;
  }
  text-align: center;
}
.add-sourcing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  background: #f57f45;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  height: 38px;
}

.add-quotation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;

  height: 38px;
  background: #fff;
  border-radius: 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  /* White */

  color: #f57f45;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  border: 1px solid #f57f45;
}
.show-close {
  .content {
    height: 70px;
    margin-top: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    .warning-logo {
      margin-right: 5px;
      margin-top: 4px;
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .confirm {
      background: #f57f45;
      color: #fff;
      width: 85px;
      height: 35px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
  }
  .loading-button {
    opacity: 0.8;
    img {
      width: 18px;
    }
  }
}
.type {
  margin: 40px 40px 0 0;
  .value {
    display: flex;
    .value-item {
      display: flex;
    }
  }
}
.my-complete-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;

  height: 38px;
  background: #ebbb45;
  border-radius: 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.main-box {
  margin: 0 auto;
}
.main-title-box-p {
  margin: 0 auto;
  background: #3b3951;
}
</style>
